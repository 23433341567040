<template>
    <div class="container">
        <h2 class="premium-plans-headline" id="premiumPlans">Premium Plans</h2>
        <div class="test-mc-div">
                <h5 class="mt-4">Not sure about performance and ping?</h5>
                <h6 >Join our test Minecarft Server: <span style="color: green; font-style: italic;">mc-test.moonodes.net</span> to test it out! (press TAB to see your ping ingame)</h6>
        </div>
        <div class="row">

            <div class="col-xl-4 col-lg-6 col-md-6 grid-margin p-5">
                <div class="card account-tiers-cards container-fluid">
                    <div class="card-body">
                        <h3 class="">Premium Tier 1</h3>
                        <p class="text-center mb-3">VIP</p>
                        <div class="text-start">
                            <div class="pt-4 m-3" style="font-size: 1.2rem;"><img src="../assets/account-tiers/cpu.png"
                                    alt="zzz" class=" " style="max-width: 10%;"> CPU: 200% (> 3.8GHz)</div>
                            <div class="m-3" style="font-size: 1.2rem;"><img src="../assets/account-tiers/ram.svg" alt="zzz"
                                    class=" " style="max-width: 10%;"> RAM: 6 GB</div>
                            <div class="m-3" style="font-size: 1.2rem;"><img src="../assets/account-tiers/disk.svg"
                                    alt="zzz" class=" " style="max-width: 10%;"> DISK: 30 GB (SSD)</div>
                            <div class="m-3" style="font-size: 1.2rem;"><img src="../assets/account-tiers/renew.svg"
                                    alt="zzz" class=" " style="max-width: 10%;"> No server renewal ✔</div>
                            <div class="m-3" style="font-size: 1.2rem;"><img src="../assets/account-tiers/world.svg" alt="zzz"
                                    class=" " style="max-width: 10%;"> Premium Location: <p class="ms-5 pt-1">Germany / Austria ✔</p> </div>
                            <div class="m-3" style="font-size: 1.2rem;"><img src="../assets/account-tiers/server.png"
                                    alt="zzz" class=" " style="max-width: 10%;"> 1 Server Slot</div>

                            <div class="pt-2">
                                <h6>Included extras:</h6>
                                <p class="ms-4">Multiple Backups</p>
                                <p class="ms-4">3 Ports / Server</p>
                            </div>
                        </div>
                        <hr>
                        <h4 class="text-center">$2,50 / 30 Days</h4>
                        <div class="pt-2 tier-purchase-section">
                            <div class="container-fluid p-1 pt-2">
                                
                                    <b-button type="submit" class="btn btn-block btn-success text-center premium-plan-pruchase-button"
                                        style="font-size: 1.2rem" @click="redirectClientPremium()">
                                        Login & Purchase!
                                    </b-button>
                            </div>
                        </div>
                </div>
            </div>
        </div>
        <div class="col-xl-4 col-lg-6 col-md-6 grid-margin p-5">
                <div class="card account-tiers-cards container-fluid">
                    <div class="card-body">
                        <h3 class="">Premium Tier 2</h3>
                        <p class="text-center mb-3">PRO</p>
                        <div class="text-start">
                            <div class="pt-4 m-3" style="font-size: 1.2rem;"><img src="../assets/account-tiers/cpu.png"
                                    alt="zzz" class=" " style="max-width: 10%;"> CPU: 400% (> 3.8GHz)</div>
                            <div class="m-3" style="font-size: 1.2rem;"><img src="../assets/account-tiers/ram.svg" alt="zzz"
                                    class=" " style="max-width: 10%;"> RAM: 12 GB</div>
                            <div class="m-3" style="font-size: 1.2rem;"><img src="../assets/account-tiers/disk.svg"
                                    alt="zzz" class=" " style="max-width: 10%;"> DISK: 50 GB (SSD)</div>
                            <div class="m-3" style="font-size: 1.2rem;"><img src="../assets/account-tiers/renew.svg"
                                    alt="zzz" class=" " style="max-width: 10%;"> No server renewal ✔</div>
                            <div class="m-3" style="font-size: 1.2rem;"><img src="../assets/account-tiers/world.svg" alt="zzz"
                                    class=" " style="max-width: 10%;"> Premium Location: <p class="ms-5 pt-1">Germany / Austria ✔</p> </div>
                            <div class="m-3" style="font-size: 1.2rem;"><img src="../assets/account-tiers/server.png"
                                    alt="zzz" class=" " style="max-width: 10%;"> 3 Server Slots</div>

                            <div class="pt-2">
                                <h6>Included extras:</h6>
                                <p class="ms-4">Multiple Backups</p>
                                <p class="ms-4">3 Ports / Server</p>
                            </div>
                        </div>
                        <hr>
                        <h4 class="text-center">$4,50 / 30 Days</h4>
                        <div class="pt-2 tier-purchase-section">
                            <div class="container-fluid p-1 pt-2">
                                
                                    <b-button type="submit" class="btn btn-block btn-success text-center premium-plan-pruchase-button"
                                        style="font-size: 1.2rem" @click="redirectClientPremium()">
                                        Login & Purchase!
                                    </b-button>
                            </div>
                        </div>
                </div>
            </div>
        </div>
        <div class="col-xl-4 col-lg-6 col-md-6 grid-margin p-5">
                <div class="card account-tiers-cards container-fluid">
                    <div class="card-body">
                        <h3 class="">Premium Tier 3</h3>
                        <p class="text-center mb-3">MAX</p>
                        <div class="text-start">
                            <div class="pt-4 m-3" style="font-size: 1.2rem;"><img src="../assets/account-tiers/cpu.png"
                                    alt="zzz" class=" " style="max-width: 10%;"> CPU: 600% (> 3.8GHz)</div>
                            <div class="m-3" style="font-size: 1.2rem;"><img src="../assets/account-tiers/ram.svg" alt="zzz"
                                    class=" " style="max-width: 10%;"> RAM: 18 GB</div>
                            <div class="m-3" style="font-size: 1.2rem;"><img src="../assets/account-tiers/disk.svg"
                                    alt="zzz" class=" " style="max-width: 10%;"> DISK: 70 GB (SSD)</div>
                            <div class="m-3" style="font-size: 1.2rem;"><img src="../assets/account-tiers/renew.svg"
                                    alt="zzz" class=" " style="max-width: 10%;"> No server renewal ✔</div>
                            <div class="m-3" style="font-size: 1.2rem;"><img src="../assets/account-tiers/world.svg" alt="zzz"
                                    class=" " style="max-width: 10%;"> Premium Location: <p class="ms-5 pt-1">Germany / Austria ✔</p> </div>
                            <div class="m-3" style="font-size: 1.2rem;"><img src="../assets/account-tiers/server.png"
                                    alt="zzz" class=" " style="max-width: 10%;"> 4 Server Slots</div>

                            <div class="pt-2">
                                <h6>Included extras:</h6>
                                <p class="ms-4">Multiple Backups</p>
                                <p class="ms-4">3 Ports / Server</p>
                            </div>
                        </div>
                        <hr>
                        <h4 class="text-center">$8,00 / 30 Days</h4>
                        <div class="pt-2 tier-purchase-section">
                            <div class="container-fluid p-1 pt-2">
                                
                                    <b-button type="submit" class="btn btn-block btn-success text-center premium-plan-pruchase-button"
                                        style="font-size: 1.2rem" @click="redirectClientPremium()">
                                        Login & Purchase!
                                    </b-button>
                            </div>
                        </div>
                </div>
            </div>
        </div>

        <!-- questions section -->
        <h5 class="premium-plans-headline mt-5">Have any questions?</h5>
        <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-12">
                        <div class="accordion mt-2" role="tablist">
                                <b-card no-body class="mb-1 accordion-cards">
                                <b-card-header header-tag="header" class="p-1 accordion-header" role="tab">
                                        <b-button class="accordion-buttons"  v-b-toggle.accordion-1 variant="primary">Can I up/down grade later?</b-button>
                                </b-card-header>
                                <b-collapse id="accordion-1"  accordion="my-accordion" role="tabpanel">
                                        <b-card-body>
                                        <!-- <b-card-text>I start opened because <code>visible</code> is <code>true</code></b-card-text> -->
                                        <b-card-text>Sure, you can upgrade or downgrade your account tier at any time. 
                                                After your Premium Tier has expired, your account will go into the standard Free Tier.</b-card-text>
                                        </b-card-body>
                                </b-collapse>
                                </b-card>

                                <b-card no-body class="mb-1 accordion-cards">
                                <b-card-header header-tag="header" class="p-1 accordion-header" role="tab">
                                        <b-button class="accordion-buttons" block v-b-toggle.accordion-2 variant="primary">How long does it take to deploy?</b-button>
                                </b-card-header>
                                <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                                        <b-card-body>
                                        <b-card-text>Your server will be deployed and ready to start in less then 60 seconds.</b-card-text>
                                        </b-card-body>
                                </b-collapse>
                                </b-card>

                                <b-card no-body class="mb-1 accordion-cards">
                                <b-card-header header-tag="header" class="p-1 accordion-header" role="tab">
                                        <b-button class="accordion-buttons" block v-b-toggle.accordion-3 variant="primary">What Gameserver Control Panel do you use?</b-button>
                                </b-card-header>
                                <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                                        <b-card-body>
                                        <b-card-text>We use the well known Pterodactyl Panel.</b-card-text>
                                        </b-card-body>
                                </b-collapse>
                                </b-card>
                        </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-12">
                        <div class="accordion mt-2" role="tablist">
                                <b-card no-body class="mb-1 accordion-cards">
                                <b-card-header header-tag="header" class="p-1 accordion-header" role="tab">
                                        <b-button class="accordion-buttons"  v-b-toggle.accordion-4 variant="primary">What plugins / mods can i install?</b-button>
                                </b-card-header>
                                <b-collapse id="accordion-4"  accordion="my-accordion" role="tabpanel">
                                        <b-card-body>
                                        <b-card-text>You can install every mod and plugin you want, since you have full root acces to your servers files.</b-card-text>
                                        </b-card-body>
                                </b-collapse>
                                </b-card>

                                <b-card no-body class="mb-1 accordion-cards">
                                <b-card-header header-tag="header" class="p-1 accordion-header" role="tab">
                                        <b-button class="accordion-buttons" block v-b-toggle.accordion-5 variant="primary">What CPUs do you use?</b-button>
                                </b-card-header>
                                <b-collapse id="accordion-5" accordion="my-accordion" role="tabpanel">
                                        <b-card-body>
                                        <b-card-text>We use a mix out of higher clocked (*not overclocked) Intel Xeon or AMD Ryzen CPUs for all premium Plans.</b-card-text>
                                        </b-card-body>
                                </b-collapse>
                                </b-card>

                                <b-card no-body class="mb-1 accordion-cards">
                                <b-card-header header-tag="header" class="p-1 accordion-header" role="tab">
                                        <b-button class="accordion-buttons" block v-b-toggle.accordion-6 variant="primary">Do Premium Tiers get reserved server slots?</b-button>
                                </b-card-header>
                                <b-collapse id="accordion-6" accordion="my-accordion" role="tabpanel">
                                        <b-card-body>
                                        <b-card-text>Yes, all Premium Tiers get acces to Premium Host systems with enough headroom for all server slots.</b-card-text>
                                        </b-card-body>
                                </b-collapse>
                                </b-card>
                        </div>
                </div>
        </div>
        

    </div>
</div></template>
    
  <script>
    export default {
      name: 'PremiumPlans',
      props: {    
      },

      methods: {
        
        redirectClientPremium() {
            location.href="https://ad7e.short.gy/xmC9YW";
        }
      }
    }
    </script>
    
    <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  @import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@1,800&display=swap');

  .account-tiers-cards {
    padding: 5%;
    color: white;
    background-color: rgba(42,42,74,255);
    box-shadow: 10px 4px 5px 0px rgba(16,16,50,255)!important;
    transition: 0.2s;
    min-width: 325px;
  }

  .account-tiers-cards:hover {
    /* transition: 0.01s !important; */
    scale: 1.02;
    /* background-color: rgba(52,52,84,255) !important; */
}

  .premium-plans-headline {
    color: white;
  }

  .accordion-buttons {
        width: 100%;
        background-color:  rgba(42,42,74,255);
        border: 0;
        color: whitesmoke;
        border-radius: 0;
        font-size: 1.1rem;
  }

  .accordion-buttons:hover {
        background-color:  rgb(51, 102, 255) !important;
        
  }

  .accordion-cards {
        border: 0;
        border-radius: 0;
        background-color: rgba(42,42,74,255);
        color: whitesmoke;
  }

  .accordion-header {
        background-color:  rgba(42,42,74,255);
       
  }

  .premium-plan-pruchase-button {
        width: 90%;
  }

  .test-mc-div {
        color: whitesmoke;
  }
 
    </style>
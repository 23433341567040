<template>
  <div id="app" class="background-color">
    <NavBarVue></NavBarVue>

    <div class="background-color">
      <h2 class="headline pb-5">StatusPage</h2>
      <h4>Realtime Usage of Gameserver Nodes</h4>
      <h6 class="fullUsageText">*100% usage means that currently no Gameservers can be deployed!</h6>

      <div v-if="loading" class="mt-5"><b-spinner label="" variant="primary" type="grow"></b-spinner></div>

      <!-- v-for gameserver nodes -->
      <div class="container" v-for="(node, id) in allNodes" :key="id">
        <div v-show="node.attributes.location_id !== 3">
          <div class="container features-section pb-2">
            <b-card no-body class="overflow-hidden features-no-bg features-section" style="max-width: 100%;">
              <hr>
              <b-row no-gutters>
                <b-col md="8">
                  <b-card-body title="">
                    <!-- <b-card-text class="features-text mt-2">
                    <h5 class="pb-2">{{ node.attributes.name }}</h5>
                    Ram Usage:  {{ Math.trunc(((node.attributes.allocated_resources.memory / (node.attributes.memory +
                        (node.attributes.memory_overallocate / 100) * node.attributes.memory)) * 100))
                    }}%
                    <b-progress :value="node.attributes.allocated_resources.memory"
                      :max="node.attributes.memory + (node.attributes.memory_overallocate / 100) * node.attributes.memory"
                      animated></b-progress>
                  </b-card-text> -->
                    <b-card-text class="features-text mt-2">
                      <h5 v-if="node.attributes.maintenance_mode == true" class="mb-4" style="color: rgb(255, 204, 0);"><i class="fa fa-wrench" style="font-size:24px;color:rgb(255, 204, 0);"></i> Currently under maintenance!</h5>
                      <h5 class="pb-2">{{ node.attributes.name }}</h5>
                      Ram Usage:  {{ calcUsage1(node) }}%
                    
                      <b-progress v-show="calcUsageProgressBar(node) != -1" :value=node.attributes.allocated_resources.memory
                        :max=calcUsageProgressBar(node)
                        animated></b-progress>
                        <b-progress v-show="calcUsageProgressBar(node) == -1" value="100"
                        max="100"
                        animated></b-progress>
                    </b-card-text>
                  </b-card-body>
                </b-col>
                <b-col md="4">
                  <b-card-img :src="require('../assets/server-gif.gif')" alt="Image" class="rounded-0 nodeimage">
                  </b-card-img>
                </b-col>
              </b-row>
            </b-card>
          </div>
        </div>
      </div>
      <h6 class="fullUsageText">Auto updates every 30 seconds</h6>
    </div>

    

    <!-- footer -->

    <div class="mt-5">
      <div :text="['center', 'lg-start']">
        <!-- Copyright -->
        <div class="text-center p-3" style="background-color: rgba(0, 0, 0, 0.2)">
          <a class="text-light" href="/#/privacy-policy">Privacy Policy</a> <br>
          © {{currentDate()}} Copyright:
          <a class="text-dark" href="https://moonodes.net">MooNodes.net</a>
        </div>
        <!-- Copyright -->
      </div>
    </div>
    <!-- <div 
        @click="scrollToTop()" 
        :class="['back-to-top-btn', {'go-top': isTop}]"
    >
        <i class='ri-arrow-up-s-line'></i>
    </div>   -->

  </div>
</template>
  
<script>
import NavBarVue from '@/components/NavBar.vue';

export default {
  name: 'StatusPage',
  components: {
    NavBarVue
  },

  data() {
    return {
      nodeUsage: '',
      loading: false,
      serverCreation: true
    }
  },

  mounted() {
    // this.$store.dispatch('getAllNodes');
    this.loading = true;
    this.$store.dispatch('getUsersIp').then(() => {
      this.getNodeUsage().then(() => {
        this.$store.dispatch('getTimeInfo').then(() => {
          this.$store.dispatch('getusersIpInfo', this.$store.state.usersIp).then(() => {
            this.checkIfServerCreationAllowed();
          })
        })
      });
    }); 
  },

  computed: {
    allNodes() {
      return this.$store.state.allNodes;
    },

    
  },

  methods: {

    calcUsage(memory, maxMemory, overAllocate) {
      const usage = ((memory / (maxMemory + (overAllocate / 100) * maxMemory)) * 100);
      this.nodeUsage = Math.trunc(usage)
      // console.log(this.nodeUsage);
    },

    calcUsage1(node) {
      let usage = ((node.attributes.allocated_resources.memory / (node.attributes.memory +
                        (node.attributes.memory_overallocate / 100) * node.attributes.memory)) * 100);
      usage = Math.trunc(usage)
      if (usage > 100) {
        usage = 100;
      } else if (node.attributes.allocated_resources.memory + 4096 > node.attributes.memory * (node.attributes.memory_overallocate / 100) + node.attributes.memory) {
        usage = 100;
      } else if (node.attributes.location_id != 1 && node.attributes.location_id != 4 || (this.serverCreation == false && node.attributes.location_id != 1)) {
        usage = 100;
      }
      return Math.trunc(usage)
      // console.log(this.nodeUsage);
    },

    calcUsageProgressBar(node) {
      let maxUsage = node.attributes.memory + (node.attributes.memory_overallocate / 100) * node.attributes.memory;
      if (node.attributes.location_id != 1 && node.attributes.location_id != 4 || this.calcUsage1(node) == 100 || (this.serverCreation == false && node.attributes.location_id != 1)) {
        maxUsage = node.attributes.allocated_resources.memory
        var showNodeAsFull = -1;
        return (showNodeAsFull)
      }
      return (maxUsage)
    },

    currentDate() {
      const current = new Date();
      const date = `${current.getFullYear()}`;
      return date;
    },

    async getNodeUsage() {
      
     await this.$store.dispatch('getAllNodes').then(() => {
      this.loading = false;
     })
     this.reloadNodeUsageTime();
    },

    reloadNodeUsageTime() {
      setTimeout(() => {
        this.getNodeUsage();
      }, 30000)
    },

    async checkIfServerCreationAllowed() {
      const usersIpInfo = this.$store.state.userIpInfo;
      const currentTime = this.$store.state.currentTime.slice(11, 13);

      if (this.$store.state.restrictInfo.countryCodes.includes(usersIpInfo.countryCode)) {
        if (currentTime >= this.$store.state.restrictInfo.strartTime && currentTime <= this.$store.state.restrictInfo.endTime) {
          this.serverCreation = false;
        }
      }
    }
  },

  // actions: {
  //   getNodeusage() {
  //     commit('loading', true)

  //     this.$store.dispatch('getAllNodes').then(response => {
  //       commit('loading', false)
  //     })
  //   }
  // }
}
</script>
  
<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #ffffff;
}

.background-color {
  background: rgba(26, 26, 60, 255);
  min-height: 100vh;
}

.headline {
  margin-top: 2%;
}

.features-no-bg {
  background-color: rgba(255, 255, 255, 0);
}

.features-text {
  color: whitesmoke;
}

.features-section {
  margin-top: 1%;
  border: 0;
  color: whitesmoke;
}

.fullUsageText {
  font-size: 0.9rem;
}

.nodeimage {
  max-width: 250px;
}

.footer-div {
  margin-top: auto;
}

@media (max-width: 900px) {
  .headline {
    margin-top: 25%;
    font-size: 2.5rem;
    bottom: 0px;
  }
}
</style>
  
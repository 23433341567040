<template>
  <div class="background-color">
    <NavBarVue></NavBarVue>
    <div class="headline">
      <h1 class="headline">Free Gameservers<br>24/7 Uptime</h1>
    </div>
    <div class="get-started-buttons">
      <b-button  size="m" class="get-started-button-text" type="submit" href="https://client.moonodes.net/">Get started now!</b-button>
    </div>
  <div class="background-div">
    <div id="background-vid-div">
    <video id="background-video" autoplay loop muted poster="../assets/live-background-01_10.jpg">
      <source src="../assets/live-background-01-compr4.mp4" type="video/mp4">
    </video>
  </div>
  </div>
  

  <div class="section1">

    <!-- Supported Software section -->
    <b-container class="bv-row-supported-software">
      <h2 class="supported_software_text">Supported Gameservers</h2>
      <hr>
  <b-row>
    <b-col>
    <b-card
      header-tag="header"
      title="Minecraft"
      class="game-cards"
    >
      <b-card-text><img src="../assets/Minecraft-Wallpaper-1.jpg" alt="" width="350px" class="game-card-image"></b-card-text>
    </b-card>
    </b-col>
    <b-col>
      <b-card
      header-tag="header"
      title="Factorio"
      class="game-cards"
    >
      <b-card-text><img src="../assets/factorio-logo.png" alt="" width="230px" class="game-card-image"></b-card-text>
    </b-card>
    </b-col>
    <b-col>
      <b-card
      header-tag="header"
      title="MTA Server"
      class="game-cards"
    >
      <b-card-text><img src="../assets/mta-logo.jpg" alt="" width="350px" class="game-card-image"></b-card-text>
    </b-card>
    </b-col>
  </b-row>
</b-container>

<!-- Features Section -->

<div class="container features-section pt-2">
  <hr>
  <h2>Service Features</h2>
  <b-card no-body class="overflow-hidden features-no-bg features-section" style="max-width: 100%;">
    <b-row no-gutters>
      <b-col md="4">
        <b-card-img :src="require('../assets/concept-art-servers3.png')" alt="Image" class="rounded-0"></b-card-img>
      </b-col>
      <b-col md="8">
        <b-card-body title="">
          <b-card-text class="features-text mt-2">
            <h5>Multiple Backups</h5>
            All Gameservers have a free Backup-Service included!
          </b-card-text>
          <b-card-text class="features-text mt-5">
            <h5>Console access</h5>
            Get full, realtime Console access to your Server.
          </b-card-text>
          <b-card-text class="features-text mt-5">
            <h5>Filemanager and SFTP</h5>
            Thanks to Pterodactyl, you can view and manage all your Gameservers files directly in your browser!
            <br>For managing larger file structures, by using an SFTP client, you can connect directly to your Gameserver instance and start up- or downloading your files.
          </b-card-text>
          <b-button variant="primary" class="mt-2" href="https://moonodes-help.crisp.help/en/category/server-administration-eo2zle/" target="_blank" disabled>More Info</b-button>
        </b-card-body>
      </b-col>
    </b-row>
  </b-card>
</div>

<div class="container features-section pt-4 pb-5">
  <b-card no-body class="overflow-hidden features-no-bg features-section" style="max-width: 100%;">
    <b-row no-gutters>
      <b-col md="8">
        <b-card-body title="">
          <b-card-text class="features-text mt-2">
            <h5>Custom Knowledgebase</h5>
            Use our Knowledgebase with detailed guides which should help you manage your server!
          </b-card-text>
          <b-button variant="primary" class="mt-2" href="https://moonodes-help.crisp.help/" target="_blank" disabled>Soon</b-button>
          <b-card-text class="features-text mt-5">
            <h5>Discord Server</h5>
            Join our Discord Server to stay informed and see the latest news & updates.
          </b-card-text>
          <b-button variant="primary" class="mt-2 features-section-buttons" href="https://discord.gg/jUwawB5uBP" target="_blank">Join Discord Server</b-button>
        </b-card-body>
      </b-col>
      <b-col md="4">
        <b-card-img :src="require('../assets/mine-book-art.png')" alt="Image" class="rounded-0"></b-card-img>
      </b-col>
    </b-row>
  </b-card>
  <hr>
</div>
    </div>

    <FreePlan></FreePlan>
    <div class="container" style="color: white;">
      
    </div>
    <PremiumPlans class="mt-5"></PremiumPlans>

    <b-button class="back-to-top-button mt-5" v-on:click="scrollToTop">Back to top</b-button>

    <!-- Footer -->
    
    <div class="mt-5">
      <div :text="['center', 'lg-start']">
        <!-- Copyright -->
        <div class="text-center p-3" style="background-color: rgba(0, 0, 0, 0.2)">
          <a class="text-light" href="/#/privacy-policy">Privacy Policy</a> <br>
          © {{currentDate()}} Copyright:
          <a class="text-dark" href="https://moonodes.net">MooNodes.net</a>
        </div>
        <!-- Copyright -->
      </div>
    </div>
    <div 
        @click="scrollToTop()" 
        :class="['back-to-top-btn', {'go-top': isTop}]"
    >
        <i class='ri-arrow-up-s-line'></i>
    </div>  
  </div>
</template>
  
<script>
import NavBarVue from '@/components/NavBar.vue';
import PremiumPlans from '@/components/PremiumPlans.vue';
import FreePlan from '@/components/FreePlan.vue';
// import FooterComp from '@/components/FooterComp.vue';

export default {
  name: 'LandingPage',
  data () {
    return {
      isTop: false
    }
  },
  props: {
    msg: String
  },
  components: {
  NavBarVue,
  PremiumPlans,
  FreePlan
},
methods: {
  scrollToTop() {
    window.scrollTo(0, 0);
  },
  currentDate() {
    const current = new Date();
    const date = `${current.getFullYear()}`;
    return date;
  }
  
},
mounted() {
  this.currentDate();
}

}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@1,800&display=swap');

  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    text-decoration: none;
  }
  .background-div {
    /* background: rgb(90,93,175);  */
    /* background: linear-gradient(0deg, rgba(90,93,175,1) 11%, rgb(187, 190, 235) 100%); */
    /* background-image: url("../assets/minecraft_live_wallpaper1_720p.mp4") ; */
    height: 100vh;
  }

  .background-color {
    background: rgba(26,26,60,255);
  }

  #background-video {
    /* Telling our absolute positioned video to 
  be relative to this element */
  object-fit: cover;
  height: 100%;
  width: 100%;

  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;

/* Will not allow the video to overflow the 
container */
overflow: hidden;

/* Centering the container's content vertically 
and horizontally */
text-align: center;
display: flex;
align-items: center;
justify-content: center;
}

#background-vid-div {
  /* height: 1px; */
  height: 100%;
}

    .headline {
      z-index: 100;
      margin-top: auto;
      margin-bottom: auto;
      font-size: 4.5rem;
      color: whitesmoke;
      text-align: center;
      position: absolute;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      height: 0%;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      font-family: 'Nunito', sans-serif;
      bottom: 200px;
      text-shadow: 2px 2px #313131;
    }

    .get-started-buttons {
      z-index: 100;
      margin-top: auto;
      margin-bottom: auto;
      color: whitesmoke;
      text-align: center;
      position: absolute;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      height: 0%;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      font-family: 'Nunito', sans-serif;
      top: 180px;
    }

    .get-started-button-text {
      font-size: 1.5rem;
      background-color: rgb(42,42,74,0.8) !important;
      border: 0;
      color: rgb(174, 186, 199);
    }

    .get-started-button-text:hover {
      background-color: rgb(42,42,74,1) !important;
      color: whitesmoke;
    }

  .section1 {
    background: rgba(26,26,60,255);
  }

  .bv-row-supported-software {
    margin-top: 0%;
    background-color: rgba(42,42,74,255);
    border-radius: 10px;
    padding: 2%;
    box-shadow: 10px 4px 5px 0px rgba(16,16,50,255)!important;
    transition: 0.2s;
  }

  .bv-row-supported-software:hover {
    scale: 1.02;
  }

  .supported_software_text {
    color: whitesmoke;
    margin-bottom: 2%;
  }

  .game-cards {
    background-color: rgba(255,255,255,0);
    color: blanchedalmond;
    border: 0;
  }

  .game-card-image {
    border-radius: 10px;
  }

  .features-no-bg {
    background-color: rgba(255,255,255,0);
  }

  .features-text {
    color: whitesmoke;
  }
  
  .features-section {
    margin-top: 5%;
    border: 0;
    color: whitesmoke;
  }

  .features-section-buttons{
  }

  .back-to-top-button {
    background-color: rgba(42,42,74,255);
    color: whitesmoke;
    border: 0;
    padding-left: 1%;
    padding-right: 1%;
    font-family: 'Nunito', sans-serif;
    font-size: 1.2rem;
  }

  .back-to-top-button:hover {
    background-color: rgba(92,92,124,255)
  }

  @media (max-width: 900px) {
    .headline {
      font-size: 2.5rem;
      bottom: 0px;
    }

    .get-started-buttons {
      top: 240px
    }
  }
</style>
  
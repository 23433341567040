import { render, staticRenderFns } from "./LandingPage.vue?vue&type=template&id=094f3e43&scoped=true&"
import script from "./LandingPage.vue?vue&type=script&lang=js&"
export * from "./LandingPage.vue?vue&type=script&lang=js&"
import style0 from "./LandingPage.vue?vue&type=style&index=0&id=094f3e43&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "094f3e43",
  null
  
)

export default component.exports
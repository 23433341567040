<template>
  <div class="container">
    <div>
  <b-navbar toggleable="lg" type="dark" variant="info" class="navbar">
    <b-navbar-brand href="/">
      <img src="../assets/MooNodesLogo.png" alt="MooNodes-Logo" height="240px">
    </b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav class="navbar-text">
        <b-nav-item href="/#/status">Status</b-nav-item>
        <b-nav-item><router-link :to="{path: '/#premiumPlans'}">Plans</router-link></b-nav-item>
        <!-- <b-nav-item href="https://moonodes-help.crisp.help/" target="_blank">Help</b-nav-item> -->
        <b-button variant="success" size="m" class="login-button" type="submit" href="https://client.moonodes.net/">Login</b-button>
      </b-navbar-nav>
      
    </b-collapse>
  </b-navbar>
</div>
  </div>
</template>
  
<script>
  export default {
    name: 'NavBar',
    props: {    
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.navbar {
  height: 120px;
  z-index: 10;
  /* position: fixed; */
  /* background-color: rgb(87, 87, 87); */
  background-color: rgba(255,255,255,0) !important;
}

.login-button {
  font-size: 1.3rem;
}

.navbar-text {
  margin-left: auto;
  font-size: 1.3rem;
}

@media (max-width: 900px) {
  .navbar {
  margin-top: -50px;
  z-index: 10;
  background-color: rgba(255,255,255,0) !important;
}
#nav-collapse {
  margin-top: -90px;
}
  }
  </style>
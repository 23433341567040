<template>
    <div class="container">
        <h2 class="premium-plans-headline" id="premiumPlans">Free Plan</h2>
        
        <div class="row justify-content-md-center">

            <div class="col-xl-4 col-lg-6 col-md-6 grid-margin p-5">
                <div class="card account-tiers-cards container-fluid">
                    <div class="card-body">
                        <h3 class="">Free Tier</h3>
                        <div class="text-start">
                            <div class="pt-4 m-3" style="font-size: 1.2rem;"><img src="../assets/account-tiers/cpu.png"
                                    alt="zzz" class=" " style="max-width: 10%;"> CPU: 125%</div>
                            <div class="m-3" style="font-size: 1.2rem;"><img src="../assets/account-tiers/ram.svg" alt="zzz"
                                    class=" " style="max-width: 10%;"> RAM: up to 6 GB</div>
                            <div class="m-3" style="font-size: 1.2rem;"><img src="../assets/account-tiers/disk.svg"
                                    alt="zzz" class=" " style="max-width: 10%;"> DISK: 30 GB (SSD)</div>
                            <div class="m-3" style="font-size: 1.2rem;"><img src="../assets/account-tiers/renew.svg"
                                    alt="zzz" class=" " style="max-width: 10%;"> Renew: 1 per 5 days</div>
                            <div class="m-3" style="font-size: 1.2rem;"><img src="../assets/account-tiers/world.svg" alt="zzz"
                                    class=" " style="max-width: 10%;"> Premium Location: X <p class="ms-4 pt-1" style="font-size: 0.8rem; font-style: italic;">When the free location is full, you have to wait until you can deploy your server</p> </div>
                            <div class="m-3" style="font-size: 1.2rem;"><img src="../assets/account-tiers/server.png"
                                    alt="zzz" class=" " style="max-width: 10%;"> 1 Server Slot</div>
                            <div class="pt-2">
                                <h6>Included extras:</h6>
                                <p class="ms-4">2 Backups</p>
                                <p class="ms-4">3 Ports / Server</p>
                            </div>
                        </div>
                        <hr>
                        <div class="pt-2 tier-purchase-section">
                            <div class="container-fluid p-1 pt-2">
                                
                                    <b-button type="submit" class="btn btn-block btn-success text-center premium-plan-pruchase-button"
                                        style="font-size: 1.2rem" @click="redirectClient()">
                                        Login to continue!
                                    </b-button>
                            </div>
                        </div>
                </div>
            </div>
        </div>  
    </div>
</div></template>
    
  <script>
    export default {
      name: 'PremiumPlans',
      props: {    
      },

      methods: {
        
        redirectClient() {
            location.href="https://client.moonodes.net";
        }
      }
    }
    </script>
    
    <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  @import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@1,800&display=swap');

  .account-tiers-cards {
    padding: 5%;
    color: white;
    background-color: rgba(42,42,74,255);
    box-shadow: 10px 4px 5px 0px rgba(16,16,50,255)!important;
    transition: 0.2s;
    min-width: 325px;
  }

  .account-tiers-cards:hover {
    /* transition: 0.01s !important; */
    scale: 1.02;
    /* background-color: rgba(52,52,84,255) !important; */
}

  .premium-plans-headline {
    color: white;
  }

  .accordion-buttons {
        width: 100%;
        background-color:  rgba(42,42,74,255);
        border: 0;
        color: whitesmoke;
        border-radius: 0;
        font-size: 1.1rem;
  }

  .accordion-cards {
        border: 0;
        border-radius: 0;
        background-color: rgba(42,42,74,255);
        color: whitesmoke;
  }

  .accordion-header {
        background-color:  rgba(42,42,74,255);
       
  }

  .premium-plan-pruchase-button {
        width: 90%;
  }

  .test-mc-div {
        color: whitesmoke;
  }
 
    </style>
import Vue from 'vue'
import VueRouter from 'vue-router'
import LandingPage from '../views/LandingPage.vue'
import StatusPage from '../views/StatusPage.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'
import TosPage from '../views/TosPage.vue'
import PremiumPlans from '../components/PremiumPlans.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: LandingPage
    },

    {
        path: '/status',
        name: 'Status',
        component: StatusPage
    },

    {
        path: '/privacy-policy',
        name: 'PrivacyPolicy',
        component: PrivacyPolicy
    },

    {
        path: '/tos',
        name: 'TosPage',
        component: TosPage
    },

    {
        path: '/premiumPlans',
        name: 'PremiumPlans',
        component: PremiumPlans
    }
]
    

const router = new VueRouter ({
    routes,
    scrollBehavior: function (to) {
        if (to.hash) {
            return {
                selector: to.hash
            }
        }
    }
})

export default router
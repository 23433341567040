<template>
  <div class="background-color">
    
  <div class="background-color iframe-div"> 
    <NavBarVue></NavBarVue>
    
  
    <div class="" style="height: 100%;">
          <iframe
            src="/privacy.html"
            width="100%"
            height="80%"
            frameborder="0" >
           </iframe>
          </div>
  </div>
  
</div>
</template>
  
<script>
import NavBarVue from '@/components/NavBar.vue';
// import FooterComp from '@/components/FooterComp.vue';

export default {
  name: 'LandingPage',
  data () {
    return {
      isTop: false
    }
  },
  props: {
    msg: String
  },
  components: {
  NavBarVue
},
methods: {
  scrollToTop() {
    window.scrollTo(0, 0);
  },
  currentDate() {
    const current = new Date();
    const date = `${current.getFullYear()}`;
    return date;
  }    
},
mounted() {
  this.currentDate();
}

}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@1,800&display=swap');

  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    text-decoration: none;
  }
  .background-div {
    /* background: rgb(90,93,175);  */
    /* background: linear-gradient(0deg, rgba(90,93,175,1) 11%, rgb(187, 190, 235) 100%); */
    /* background-image: url("../assets/minecraft_live_wallpaper1_720p.mp4") ; */
    height: 100%;
    overflow: hidden;
  }

  .background-div::-webkit-scrollbar {
    display: none;
  }

  .background-color {
    background: rgba(26,26,60,255);
  }

  .iframe-div {
    height: 100vh;
    overflow: hidden;
  }

  #background-video {
    /* Telling our absolute positioned video to 
  be relative to this element */
  object-fit: cover;
  height: 100%;
  width: 100%;

  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;

/* Will not allow the video to overflow the 
container */
overflow: hidden;

/* Centering the container's content vertically 
and horizontally */
text-align: center;
display: flex;
align-items: center;
justify-content: center;
}

#background-vid-div {
  height: 1px;
}

    .headline {
      z-index: 100;
      margin-top: auto;
      margin-bottom: auto;
      font-size: 4.5rem;
      color: whitesmoke;
      text-align: center;
      position: absolute;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      height: 0%;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      font-family: 'Nunito', sans-serif;
      bottom: 200px;
      text-shadow: 2px 2px #313131;
    }

    .get-started-buttons {
      z-index: 100;
      margin-top: auto;
      margin-bottom: auto;
      color: whitesmoke;
      text-align: center;
      position: absolute;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      height: 0%;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      font-family: 'Nunito', sans-serif;
      top: 180px;
    }

    .get-started-button-text {
      font-size: 1.5rem;
      background-color: rgb(42,42,74,0.8) !important;
      border: 0;
      color: rgb(174, 186, 199);
    }

    .get-started-button-text:hover {
      background-color: rgb(42,42,74,1) !important;
      color: whitesmoke;
    }

  .section1 {
    background: rgba(26,26,60,255);
  }

  .bv-row-supported-software {
    margin-top: 0%;
    background-color: rgba(42,42,74,255);
    border-radius: 10px;
    padding: 2%;
  }

  .supported_software_text {
    color: whitesmoke;
    margin-bottom: 2%;
  }

  .game-cards {
    background-color: rgba(255,255,255,0);
    color: blanchedalmond;
    border: 0;
  }

  .game-card-image {
    border-radius: 10px;
  }

  .features-no-bg {
    background-color: rgba(255,255,255,0);
  }

  .features-text {
    color: whitesmoke;
  }
  
  .features-section {
    margin-top: 5%;
    border: 0;
    color: whitesmoke;
  }

  .features-section-buttons{
  }

  .back-to-top-button {
    background-color: rgba(42,42,74,255);
    color: whitesmoke;
    border: 0;
    padding-left: 1%;
    padding-right: 1%;
    font-family: 'Nunito', sans-serif;
    font-size: 1.2rem;
  }

  @media (max-width: 900px) {
    .headline {
      font-size: 2.5rem;
      bottom: 0px;
    }

    .get-started-buttons {
      top: 240px
    }
  }
</style>
  
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
state: { 
    allNodes: [],

    usersIp: "",
    userIpInfo: {},
    currentTime: "",

    restrictInfo: {
        strartTime: 8,
        endTime: 21,
        countryCodes: "IN"
    }
 },

mutations: { 
    setAllNodes(state, allNodes) {
        state.allNodes = allNodes;
    },
    setUsersIp(state, usersIp) {
        state.usersIp = usersIp;
    },
    setCurrentTime(state, currentTime) {
        state.currentTime = currentTime;
    },
    setUserIpInfo(state, userIpInfo) {
        state.userIpInfo = userIpInfo;
    }
 },


actions: { 
    
    async getAllNodes(context) {
        const response = await Vue.axios.get("https://landing-proxy.moonodes.net/application/nodes");
        context.commit('setAllNodes', response.data.data);
    },

    async getUsersIp(context) {
        const response = await Vue.axios.get("https://api.ipify.org?format=json");
        context.commit('setUsersIp', response.data.ip);
    },

    async getusersIpInfo(context, ipAddress) {
        const response = await Vue.axios.get("https://freeipapi.com/api/json/" + ipAddress);
        context.commit('setUserIpInfo', response.data);
    },

    async getTimeInfo(context) {
        const response = await Vue.axios.get("http://worldtimeapi.org/api/timezone/Europe/Vienna/");
        context.commit('setCurrentTime', response.data.datetime)
    }

 }
})

